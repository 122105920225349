#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Readex Pro', sans-serif;
  }
}
.image-gallery-slides img {
  max-height: 40%;
  max-width: 50%;
  object-fit: contain;
}

.image-gallery-slide img:fullscreen {
  @apply w-screen mx-auto object-contain;
  justify-content: center;
  padding-top: 20px;
  max-height: 80vh;
  max-width: 80vw;
}
.image-gallery {
  padding-top: 120px;
  margin-bottom: 80px;
}

@layer components {
  .card {
    @apply
      bg-white text-gray-700
      w-72 min-h-[10rem]
      shadow-lg rounded-md overflow-hidden
  }
  .badge {
    @apply
     px-3 py-1 rounded-full text-xs bg-gray-100
  }

  .collaborator-title {
    @apply 
    font-semibold text-2xl
    overflow-ellipsis overflow-hidden whitespace-nowrap
  }

  .discount-percent {
    @apply
      bg-green-400
      px-1.5 py-0.5 rounded-md
      text-xs text-white
  }

  .button-primary {
    @apply
      bg-blue-500/80 hover:bg-blue-500/90
      px-6 py-2 rounded-md
      text-white font-medium
      tracking-wider transition
  }

  .button-icon {
    @apply
      flex-grow
      flex justify-center items-center
      bg-gray-300/60 hover:bg-gray-300/80
      transition rounded-md
  }
}