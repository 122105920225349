.prueba{
    padding: 50px;
    margin: 50px;
}

.sliderInit{
    padding-top: 0;
}

.heroSection{
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 0rem; 
    padding-right: 0rem;
    padding-bottom: 10px;

}

.formCollaborator{
    padding: 10px 10px 0px 10px;
    /* background-color: gainsboro; */
}


.formLoginRegister {
    padding-top: 60px;
}

.loginRegisterText{
    padding-left: 5px;
    padding-right: 5px;
}


.carouselHight {
    padding-top: 0;
    max-height: 600px;
    padding-bottom: 20px;
    
    
   
}

.heading{
    background-position: 50%;
    background-image: url('https://images.unsplash.com/photo-1583427053896-00378e61e661?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    height: 400px;
    background-position-y: 38%;
    /* background-color: rgba(0, 0, 0, 0.75); */
    
}

.headingC{
    background-position: 50%;
    background-image: url('https://images.unsplash.com/photo-1587004461337-b61ecf08fc0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1105&q=80');
    height: 400px;
    background-position-y: 65%;
    /* background-color: rgba(0, 0, 0, 0.75); */
    
}

.headingA{
    background-position: 50%;
    background-image: url('https://images.unsplash.com/photo-1494905998402-395d579af36f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    height: 400px;
    background-position-y: 60%;
    /* background-color: rgba(0, 0, 0, 0.75); */
    
}





.cardtest{
    background-color: #6d5b98;
}

.heading1 {
    background-color: rgba(0, 0, 0, 0.40)
}

.sectTextImg {
    background: hsla(0, 0%, 100%, 0.55); 
    backdrop-filter: blur(30px)
}

.sectionArticle1 {
    background-position: 50%
}

.sectionArticleA{
    background-color: rgba(251, 251, 251, 0.2)
}

.feacturesSection2 {
    min-width: 992px
}

.feacturesSection2.rotate-g-6 {
    transform: rotate(6deg);
}

.feactureServices3{
    background: hsla(0, 0%, 100%, 0.55);
    backdrop-filter: blur(30px);
    z-index: 1;
}